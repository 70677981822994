.alertBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 80808080;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.show-alert {
  opacity: 1;
  pointer-events: auto;
  .custom-alert {
    opacity: 1;
    pointer-events: auto;
    box-sizing: border-box;
    -webkit-animation: custom-Alert 0.4s;
    animation: custom-Alert 0.4s;
    animation-fill-mode: forwards;
    will-change: transform;
    overflow: hidden;
  }
}

.custom-alert {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 478px;
  height: 180px;
  z-index: 90909090;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -90px;
  margin-left: -239px;
  flex-wrap: wrap;
  padding: 0;
  font-size: 15px;
  @media #{$xs-layout} {
    width: calc(100% - 80px);
    margin-left: calc(((100% - 80px) / 2) * -1);
  }
}

@keyframes custom-Alert {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}

@-webkit-keyframes custom-Alert {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}

.alert-text {
  // margin-top: 30px;
  display: inline-block;
  max-width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 0;
    &:first-child {
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      // align-self: flex-end;
      // margin-bottom: 5px;
    }
  }
}

.alert-footer {
  align-self: flex-end;
  width: 100%;
  /* text-align: center; */
  height: 50px;
  border-top: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  button {
    background-color: #ffffff;
    border: none;
    width: 50%;
    padding: 0;
    box-sizing: border-box;
  }
}

#customBtnClose {
  p {
    font-weight: 500;
    border-right: 1px solid #eeeeee;
  }
}

#customBtnSelect {
  p {
    font-weight: 700;
    color: #2f4693;
  }
}
