* {
  text-decoration: none;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

button {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}

ol,
ul,
li {
  list-style: none;
}
