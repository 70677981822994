// Font Family
$poppins: 'Poppins', sans-serif;
$abrilfatface: 'Abril Fatface', cursive;
$cormorantgaramond: 'Cormorant Garamond';
$greatvibes: 'Great Vibes', cursive;
$lobster: 'Lobster', cursive;
$josefin: 'Josefin Sans', sans-serif;

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// theme Color -------------------
$dark-bg: #232323;
$dark-text: #ffffff;

$light-bg: #ffffff;
$light-text: #232323;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
//$theme-color: #a749ff;
$theme-color: #18388f;

// Responsive Variables
$xxx-layout: 'only screen and (min-width: 1920px)';
$xx-layout: 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$xs-layout: 'only screen and (max-width: 767px)';

// Ftv
$xl-layout: 'only screen and (min-width: 980px) and (max-width: 1365px)';
$md-layout: 'only screen and (min-width: 790px) and (max-width: 980px)';
$sm-layout: 'only screen and (max-width: 980px)';

.scrollbarRemove {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbarRemove::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.scrollbarCustom::-webkit-scrollbar {
  width: 7px;
}

.scrollbarCustom::-webkit-scrollbar-thumb {
  height: 15%; /* 스크롤바의 길이 */
  background: #217af4; /* 스크롤바의 색상 */

  border-radius: 10px;
}

.scrollbarCustom::-webkit-scrollbar-track {
  background: rgba(155, 155, 155, 1); /*스크롤바 뒷 배경 색상*/
}

.hidden {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}

.lazy-loading-bg {
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
}

.lazy-loading {
  width: 27px;
  height: 27px;

  border-top: 2px solid #2f4693;
  border-bottom: 2px solid #2f4693;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;

  animation: anim7 1s ease infinite;
  border-radius: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -13.5px;
  margin-top: -13.5px;
}

.lazy-linear {
  position: absolute;
  top: 46%;
  left: 46%;

  width: 8px;
  height: 8px;
  border-radius: 12px;
  animation: loader10m 0.6s ease-in-out infinite;
}

.lazy-linear:before {
  content: '';
  position: absolute;
  top: 0px;
  left: -25px;

  height: 8px;
  width: 8px;
  border-radius: 12px;
  animation: loader10g 0.6s ease-in-out infinite;
}

.lazy-linear:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 25px;

  height: 8px;
  width: 8px;
  border-radius: 10px;
  animation: loader10d 0.6s ease-in-out infinite;
}

.submit-page-account {
  padding: 5px 0 5px 20px;
  font-size: 16px;
}
.submit-page-account1 {
  padding: 5px 0 5px 20px;
  font-size: 16px;
  font-weight: bold;
}
.submit-confirm-page-account {
  padding: 5px 0 5px 17px;
  font-size: 16px;
  text-align: center;
}
.submit-confirm-page-account1 {
  padding: 5px 0 5px 17px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.submit-page-account-agree {
  padding: 5px 0 5px 17px;
  font-size: 15px;
  text-align: center;
  color: rgb(241, 48, 48);
  @media #{$sm-layout} {
    flex-flow: wrap;
  }
  @media #{$xs-layout} {
    flex-flow: wrap;
  }
}

.mobile-confirm-info {
  display: flex;
  justify-content: flex-start;

  color: #ff0000;
  font-size: 14px;

  @media #{$sm-layout} {
    font-size: 13px;
  }
}

.emergency-hp {
  padding-left: 20px;
  color: rgb(241, 48, 48);
  font-size: 13px;
}

@keyframes anim7 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader10g {
  0% {
    background-color: rgba(238, 238, 238, 1);
  }
  25% {
    background-color: rgb(241, 48, 48);
  }
  50% {
    background-color: rgba(238, 238, 238, 1);
  }
  75% {
    background-color: rgba(238, 238, 238, 1);
  }
  100% {
    background-color: rgba(238, 238, 238, 1);
  }
}

@keyframes loader10m {
  0% {
    background-color: rgba(238, 238, 238, 1);
  }
  25% {
    background-color: rgba(238, 238, 238, 1);
  }
  50% {
    background-color: rgb(241, 48, 48);
  }
  75% {
    background-color: rgba(238, 238, 238, 1);
  }
  100% {
    background-color: rgba(238, 238, 238, 1);
  }
}

@keyframes loader10d {
  0% {
    background-color: rgba(238, 238, 238, 1);
  }
  25% {
    background-color: rgba(238, 238, 238, 1);
  }
  50% {
    background-color: rgba(238, 238, 238, 1);
  }
  75% {
    background-color: rgb(241, 48, 48);
  }
  100% {
    background-color: rgba(238, 238, 238, 1);
  }
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
