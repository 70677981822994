@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: 100;
  src: url(../fonts/AppleSDGothicNeoM.woff2) format('woff2');
  src: url(../fonts/AppleSDGothicNeoM.woff) format('woff');
  src: url(../fonts/AppleSDGothicNeoM.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-weight: bold;
  src: url(../fonts/AppleSDGothicNeoB.woff2) format('woff2');
  src: url(../fonts/AppleSDGothicNeoB.woff) format('woff');
  src: url(../fonts/AppleSDGothicNeoB.ttf) format('truetype');
  font-display: swap;
}

* {
  font-family: AppleSDGothicNeo !important;
}
